import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import NotFound from "../../content/pages/404.yaml"

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title={NotFound.title} description={NotFound.metaDescription} />
      <h3>NOT FOUND</h3>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
